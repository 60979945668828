
import {Options, Vue} from 'vue-class-component';
import {ElInput, ElMessage} from 'element-plus';
import {App} from '@/services';
import {phoneVerification} from '@/services/server/wap/phone-verification';
import {GetUser} from '@/model/tokenGetUser';
import TelInput from '@/common/web/telInput/index.vue';

@Options({
  components: {
    ElInput,
    TelInput
  },
  methods: {
    async checkPhoneField(phone: string) {
      if (!phone) {
        phone = this.phoneNumber;
      }
      console.log('this.phoneNumber', this.phoneNumber);

      return await phoneVerification.checkField({
        /* eslint-disable-next-line */
        field_name: 'phone_number',
        value: phone
      });
    },
    async checkPhone() {
      // const data = { result: 0, data: { message: 'Success' }, message: this.$t('ui_change_failed')}//tmp
      const data = await this.checkPhoneField();
      console.log('data: ', data);
      // this.loading = false;
      if (data instanceof Error) {
        ElMessage.error(data.message || 'fail');
        return false;
      } else {
        console.log(data.message);
        if (data.message) {
          ElMessage({type: 'success', message: data.message});
        }
        this.$refs.tel.cleanVal();
        this.info.phone = '';
        this.changePhoneStatus(2);
      }
    },
    beforeAddSMS() {
      if (!this.phoneNumber) {
        this.info.phoneNumberMsg = 'ui_phone_not_entered';
      } else {
        this.addSMS();
      }
    },
    async addSMS(phone: string) {
      !phone && (phone = this.phoneNumber);
      this.showWaitTime();
      return await phoneVerification
        .created({
          /* eslint-disable-next-line */
          phone_number: phone
        })
        .then((response: any) => {
          ElMessage({
            type: 'success',
            message: response?.message || 'success'
          });
          this.changePhoneStatus(3);
        })
        .catch(error => {
          // this.$ElMessage.fail(error.message)
          ElMessage({
            type: 'error',
            message: error.message || 'fail'
          });
          this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
          return error;
        });
    },
    async editPhone() {
      const data = await this.bindSMS();
      if (data instanceof Error) {
        return false;
      } else {
        this.changePhoneStatus(0);
        this.$router.back();
      }
    },
    async bindSMS() {
      // let number = phoneUtil.parseAndKeepRawInput(this.$data.$_phone, this.$data.$_countryCode)
      const data = await phoneVerification.updated({
        // phone_number: phoneUtil.format(number, PNF.E164),
        code: this.info.phoneCode
      });
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message:
            this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed') || 'fail'
        });
        // console.log('data.message', data.message)
        // let message = this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed')
        // this.$ElMessage.fail(message || 'fail')
        return false;
      } else {
        ElMessage({
          type: 'success',
          message:
            this.$t('ui_verification_code_is_correct') + '\r\n' + this.$t('ui_change_successful') ||
            'fail'
        });
        //修改狀態
        await GetUser.action();
        this.userInfo = App.getUserinfo();
      }
      return data;
    },
    showWaitTime() {
      this.info.verPhoneWaitTime = this.info.phoneWaitTime;
      this.info.smsInterval = setInterval(() => {
        this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
        if (+this.info.verPhoneWaitTime === 0) {
          clearInterval(this.info.smsInterval);
        }
      }, 1000);
    }
  },
  computed: {
    verSms() {
      return this.info.phoneDisabled;
    },
    phoneNumber() {
      return this.$refs.tel ? this.$refs.tel.info.phoneObject.number.e164 : '';
    }
  }
})
export default class PhoneVerification extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  panel = {
    top: [
      {
        text: 'ui_user_acc_colon',
        key: 'userAccount'
      },
      ...(this.config.dollarSign !== '¥'
        ? this.config.dollarSign === '₫'
          ? [
              {
                text: 'ui_last_name',
                key: 'lastName'
              },
              {
                text: 'ui_first_name',
                key: 'firstName'
              }
            ]
          : [
              {
                text: 'ui_first_name',
                key: 'firstName'
              },
              {
                text: 'ui_last_name',
                key: 'lastName'
              }
            ]
        : [
            {
              text: 'ui_real_name',
              key: 'realName'
            }
          ]),
      {
        text: 'ui_user_birthday',
        key: 'birth'
      }
    ],
    bottom: [
      {
        text: 'ui_reg_time_colon',
        key: 'createTime'
      },
      {
        text: 'ui_pre_time',
        key: 'lastLoginTime'
      },
      {
        text: 'ui_pre_ip',
        key: 'lastUserIp'
      }
    ]
  };
  info = {
    phoneNumberMsg: '',
    phoneDisabled: true,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    verPhoneWaitTime: 0,
    smsInterval: '',
    phoneCode: '',
    phone: ''
  };
  modifyStatus = {
    phone: 0 //0 :view,1:edit,2:verification
  };
  loading = false;

  async mounted() {
    // if (!this.userInfo) {
    this.loading = true;
    await GetUser.action();
    this.userInfo = App.getUserinfo();
    // }
    console.log(this.userInfo?.phoneNumber, 'this.userInfo');
    if (!this.userInfo?.phoneNumber?.verification) {
      this.modifyStatus.phone = 2;
    }
    this.info.phoneNumberMsg = '';
    this.$nextTick(() => {
      this.loading = false;
    });
  }
  changePhoneStatus(status: number) {
    console.log('status: ', status);
    //tmp :判断是否已手机认证,是跳过状态1
    if (status === 1 && !this.userInfo?.phoneNumber?.verification) {
      status = 2;
    }
    this.modifyStatus.phone = status;
  }
  clearPhoneError(item: {isValid: boolean}) {
    this.info.phoneNumberMsg = '';
    this.info.phoneDisabled = !item.isValid;
  }
}
