<template>
  <div class="list-detail__form static-container" style="">
    <!-- 手機簡訊驗證碼 -->
    <!--phone-->
    <div class="form__field" v-loading="loading">
      <div class="form__title">{{ $t('ui_mobile_phone_number') }}</div>
      <div v-if="modifyStatus.phone === 0">{{ userInfo.phoneNumber.text }}</div>
      <tel-input
        v-else-if="modifyStatus.phone > 0"
        ref="tel"
        :value="info.phone"
        :preferredCountries="['VN', 'TH']"
        :placeholder="
          modifyStatus.phone == 1
            ? $t('ui_please_enter_your_old_mobile_number')
            : $t('ui_please_enter_new_phone_number')
        "
        @validate="clearPhoneError"
        class="background-day-t5 u_w_50p"
      ></tel-input>
      <div class="u_p--l10">
        <el-button
          v-if="modifyStatus.phone > 1"
          round
          class="c_button--normal userbtn-bg--submit"
          icon=""
          :type="'info'"
          size="small"
          :hairline="true"
          @click="beforeAddSMS"
          v-text="(info.verPhoneWaitTime || $t('ui_send_verification')) + ''"
          :disabled="+info.verPhoneWaitTime > +0"
        ></el-button>
      </div>
    </div>
    <!--phone code-->
    <div class="form__field" v-if="modifyStatus.phone > 1">
      <div class="form__title">{{ $t('ui_sms_verification_code') }}</div>
      <el-input
        class="c_input--longest"
        v-model="info.phoneCode"
        clearable
        type="text"
        :placeholder="$t('ui_please_enter_verification_code')"
        :error-message="$t('')"
        @input="clearError"
      >
      </el-input>
      <div class="u_p--l10">
        <el-button
          round
          class="c_button--normal"
          :icon="'edit'"
          :type="'info'"
          size="small"
          :hairline="true"
          v-if="modifyStatus.phone === 1"
          @click="changePhoneStatus(0)"
          v-text="$t('btn_cancel')"
        ></el-button>
      </div>
    </div>
    <div class="flex flex-justify-evenly u_m--t20">
      <el-button
        round
        class="c_button--XL u_w--100p userbtn-bg--submit"
        :icon="'edit'"
        :type="'info'"
        :hairline="true"
        v-if="modifyStatus.phone === 0 && !loading"
        @click="changePhoneStatus(1)"
        v-text="$t('ui_change')"
      ></el-button>
      <el-button
        v-else-if="modifyStatus.phone == 1 && !loading"
        round
        class="c_button--XL u_w--100p  userbtn-bg--submit"
        :icon="'edit'"
        :type="'info'"
        :hairline="true"
        @click="checkPhone"
        :disabled="info.phoneDisabled"
        v-text="$t('btn_enter')"
      ></el-button>
      <button
        type="button"
        class="c_button--XL userbtn-bg--submit j_btn-txt--primary u_w--100p"
        @click="editPhone"
        :disabled="info.phoneCode.length !== 6"
        v-else-if="!loading"
      >
        <span class="c_button__text c_button__weight">{{ $t('btn_sent_out') }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElInput, ElMessage} from 'element-plus';
import {App} from '@/services';
import {phoneVerification} from '@/services/server/wap/phone-verification';
import {GetUser} from '@/model/tokenGetUser';
import TelInput from '@/common/web/telInput/index.vue';

@Options({
  components: {
    ElInput,
    TelInput
  },
  methods: {
    async checkPhoneField(phone: string) {
      if (!phone) {
        phone = this.phoneNumber;
      }
      console.log('this.phoneNumber', this.phoneNumber);

      return await phoneVerification.checkField({
        /* eslint-disable-next-line */
        field_name: 'phone_number',
        value: phone
      });
    },
    async checkPhone() {
      // const data = { result: 0, data: { message: 'Success' }, message: this.$t('ui_change_failed')}//tmp
      const data = await this.checkPhoneField();
      console.log('data: ', data);
      // this.loading = false;
      if (data instanceof Error) {
        ElMessage.error(data.message || 'fail');
        return false;
      } else {
        console.log(data.message);
        if (data.message) {
          ElMessage({type: 'success', message: data.message});
        }
        this.$refs.tel.cleanVal();
        this.info.phone = '';
        this.changePhoneStatus(2);
      }
    },
    beforeAddSMS() {
      if (!this.phoneNumber) {
        this.info.phoneNumberMsg = 'ui_phone_not_entered';
      } else {
        this.addSMS();
      }
    },
    async addSMS(phone: string) {
      !phone && (phone = this.phoneNumber);
      this.showWaitTime();
      return await phoneVerification
        .created({
          /* eslint-disable-next-line */
          phone_number: phone
        })
        .then((response: any) => {
          ElMessage({
            type: 'success',
            message: response?.message || 'success'
          });
          this.changePhoneStatus(3);
        })
        .catch(error => {
          // this.$ElMessage.fail(error.message)
          ElMessage({
            type: 'error',
            message: error.message || 'fail'
          });
          this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
          return error;
        });
    },
    async editPhone() {
      const data = await this.bindSMS();
      if (data instanceof Error) {
        return false;
      } else {
        this.changePhoneStatus(0);
        this.$router.back();
      }
    },
    async bindSMS() {
      // let number = phoneUtil.parseAndKeepRawInput(this.$data.$_phone, this.$data.$_countryCode)
      const data = await phoneVerification.updated({
        // phone_number: phoneUtil.format(number, PNF.E164),
        code: this.info.phoneCode
      });
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message:
            this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed') || 'fail'
        });
        // console.log('data.message', data.message)
        // let message = this.$t('ui_verification_code_error') + '\r\n' + this.$t('ui_change_failed')
        // this.$ElMessage.fail(message || 'fail')
        return false;
      } else {
        ElMessage({
          type: 'success',
          message:
            this.$t('ui_verification_code_is_correct') + '\r\n' + this.$t('ui_change_successful') ||
            'fail'
        });
        //修改狀態
        await GetUser.action();
        this.userInfo = App.getUserinfo();
      }
      return data;
    },
    showWaitTime() {
      this.info.verPhoneWaitTime = this.info.phoneWaitTime;
      this.info.smsInterval = setInterval(() => {
        this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
        if (+this.info.verPhoneWaitTime === 0) {
          clearInterval(this.info.smsInterval);
        }
      }, 1000);
    }
  },
  computed: {
    verSms() {
      return this.info.phoneDisabled;
    },
    phoneNumber() {
      return this.$refs.tel ? this.$refs.tel.info.phoneObject.number.e164 : '';
    }
  }
})
export default class PhoneVerification extends Vue {
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  panel = {
    top: [
      {
        text: 'ui_user_acc_colon',
        key: 'userAccount'
      },
      ...(this.config.dollarSign !== '¥'
        ? this.config.dollarSign === '₫'
          ? [
              {
                text: 'ui_last_name',
                key: 'lastName'
              },
              {
                text: 'ui_first_name',
                key: 'firstName'
              }
            ]
          : [
              {
                text: 'ui_first_name',
                key: 'firstName'
              },
              {
                text: 'ui_last_name',
                key: 'lastName'
              }
            ]
        : [
            {
              text: 'ui_real_name',
              key: 'realName'
            }
          ]),
      {
        text: 'ui_user_birthday',
        key: 'birth'
      }
    ],
    bottom: [
      {
        text: 'ui_reg_time_colon',
        key: 'createTime'
      },
      {
        text: 'ui_pre_time',
        key: 'lastLoginTime'
      },
      {
        text: 'ui_pre_ip',
        key: 'lastUserIp'
      }
    ]
  };
  info = {
    phoneNumberMsg: '',
    phoneDisabled: true,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    verPhoneWaitTime: 0,
    smsInterval: '',
    phoneCode: '',
    phone: ''
  };
  modifyStatus = {
    phone: 0 //0 :view,1:edit,2:verification
  };
  loading = false;

  async mounted() {
    // if (!this.userInfo) {
    this.loading = true;
    await GetUser.action();
    this.userInfo = App.getUserinfo();
    // }
    console.log(this.userInfo?.phoneNumber, 'this.userInfo');
    if (!this.userInfo?.phoneNumber?.verification) {
      this.modifyStatus.phone = 2;
    }
    this.info.phoneNumberMsg = '';
    this.$nextTick(() => {
      this.loading = false;
    });
  }
  changePhoneStatus(status: number) {
    console.log('status: ', status);
    //tmp :判断是否已手机认证,是跳过状态1
    if (status === 1 && !this.userInfo?.phoneNumber?.verification) {
      status = 2;
    }
    this.modifyStatus.phone = status;
  }
  clearPhoneError(item: {isValid: boolean}) {
    this.info.phoneNumberMsg = '';
    this.info.phoneDisabled = !item.isValid;
  }
}
</script>
<style lang="scss" scoped>
.password-container {
  font-size: 16px;
  font-weight: 400;
}
.form__field {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  .form__title {
    padding-top: 5px;
    text-align: end;
    min-width: 150px;
  }
}
.c_input--longest {
  width: 100%;
  min-width: 270px;
}

.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest .el-input__inner {
  background-color: #f5f5f5;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
  min-width: 120px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  &[disabled] {
    cursor: not-allowed;
    background-color: #e8e8e8;
    color: #999999;
  }
}
.userbtn-bg--submit {
  background-color: #1f55f0;
  color: #ffffff;
}
.u_w--100p {
  width: 100%;
}
.u_m--t20 {
  margin-top: 20px;
}
:deep(.el-input__inner) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
